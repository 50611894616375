<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <p>{{ $t('Public.CompanyDynamic.banner.title') }}</p>
                <div>{{ $t('Public.CompanyDynamic.banner.text') }}</div>
            </div>
            <ul class="dynamic" :class="{ dynamic_en: $i18n.locale == 'EN' }">
                <li v-for="(item, index) in $t('Public.CompanyDynamic.sigrealNews')" :key="index">
                    <div class="dynamic_left">
                        <div class="dynamic_img"><img :src="require('../../public/images/dynamic/' + item.src)" /></div>
                        <div class="dynamic_date">{{ item.date }}</div>
                    </div>
                    <div class="dynamic_right">
                        <p>{{ item.title }}</p>
                        <div>{{ item.text }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
    name: 'CompanyDynamic',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>
<style scoped>
.banner {
    width: 100%;
    height: 4.4rem;
    padding: 1.88rem 0.77rem 0;
    font-size: 0.24rem;
    font-weight: 400;
    background: url(../../public/images/dynamic/banner.jpg) no-repeat;
    background-size: 100% 100%;
    line-height: 1.5;
    color: #fff;
}
.banner p {
    font-size: 0.46rem;
    line-height: 1.39;
    margin-bottom: 0.2rem;
}
.dynamic {
    padding: 0.6rem 0.2rem 1.1rem;
}
.dynamic li {
    width: 100%;
    padding: 0.4rem 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #d8d8d8;
    /* margin-bottom:0.2rem; */
}
.dynamic li:last-child {
    border-bottom: 0;
}
.dynamic_left {
    width: 1.96rem;
    height: 2.83rem;
    position: relative;
    overflow: hidden;
}
.dynamic_right {
    width: 4.84rem;
    text-align: justify;
}
.dynamic_en .dynamic_right {
    text-align: left;
}
.dynamic_date {
    width: 100%;
    height: 0.55rem;
    line-height: 0.55rem;
    text-align: justify;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 50;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.24rem;
    color: #fff;
    text-align: center;
}
.dynamic_right p {
    width: 100%;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0.28rem;
}
.dynamic_right div {
    font-size: 0.24rem;
    font-weight: 400;
    color: #626262;
    line-height: 1.5;
    text-align: justify;
}
</style>